<template>
  <v-form
    ref="form"
    class="returns-form"
    v-model="valid"
    @submit.prevent="onSubmitForm"
  >
    <div class="returns-form__layout">
      <div class="returns-form__layout-section">
        <rcc-date-input
          v-model="form.booking_date"
          label="Дата бронирования"
          :is-disabled="!isNew"
          width="270px"
          :rules="[value => !isNew || (value && value !== '') ||  'Не может быть пустым']"
        />

        <rcc-date-input
          v-model="form.booking_date_fact"
          ref="bookingDateFactRef"
          label="Дата бронирования факт"
          :rules="[value => !hasFactValidation || (value && value !== '') ||  'Не может быть пустым']"
          width="270px"
        />

        <rcc-text-input
          v-model="form.application_code"
          label="Код бронирования"
          :is-disabled="form.is_external"
        />

        <rcc-autocomplete
          :disabled="!isNew"
          v-model="form.supplier_id"
          label="Поставщик"
          :items="suppliersOptions"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите поставщика' })]"
        />

        <rcc-number-input
          v-model="form.pallets_num"
          label="Кол-во паллет"
          :is-disabled="!isNew"
          width="200px"
          :rules="[value => !isNew || (value && value !== '') ||  'Не может быть пустым']"
        />

        <rcc-number-input
          v-model="form.pallets_num_fact"
          ref="palletsNumFactRef"
          label="Кол-во паллет факт"
          :rules="[value => !hasFactValidation || (value && value !== '') ||  'Укажите количество паллет']"
          width="200px"
        />

        <rcc-select
          v-model="form.network_id"
          :items="networksOptions"
          label="Сеть"
          :is-disabled="!isNew"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите сеть' })]"
          @input="handleNetworkInput"
        />

        <rcc-select
          v-model="form.region_id"
          :items="regionsOptions"
          label="Регион"
          :is-disabled="!isNew"
        />
      </div>

      <div class="returns-form__layout-section">
        <rcc-autocomplete
          v-model="form.return_point_id"
          :items="returnPointsForCurrentNetwork"
          :disabled="isLoadReturnPoints || form.is_external"
          :no-data-text="returnPointsNoDataText"
          show-by="name"
          label="Точка возврата"
        />

        <rcc-autocomplete
          v-model="form.store_id"
          :items="storesOptions"
          label="Склад"
        />

        <rcc-autocomplete
          v-model="form.return_status_id"
          :items="returnStatusesOptions"
          label="Статус"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите статус' })]"
          @input="handleReturnStatusInput"
        />

        <rcc-autocomplete
          v-model="form.status_detail_id"
          :items="detailsOptions"
          label="Детализация статуса"
          clearable
        />

        <rcc-text-input v-model="form.number_1c" label="Номер заказа в 1С" />
        <rcc-textarea v-model="form.comment" label="Комментарий" />
      </div>
    </div>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirmDialog"
      title="Подтвердить изменения?"
      text="После подтверждения с заявки будет удалена отметка о наличии изменений"
      :confirmCallback="confirmReturnsChanges"
    />

    <rcc-footer-buttons
      :is-submit="isSubmit"
      :additional-text="additionalText"
      @cancel-click="$router.push({ name: 'returns' })"
      @additional-click="isShowConfirmDialog = true"
    />
  </v-form>
</template>

<script>
import { getNetworksList } from '@/api/trading-networks'
import { getReturnStatusesList } from '@/api/return-statuses'
import { getReturnPointsList } from '@/api/return-points'
import { getOptions } from '@/utils/get-options'
import { getActiveSuppliersList, getAllSuppliersList } from '@/api/suppliers'
import { getDetailsList } from '@/api/detailing'
import { getRegionsList } from '@/api/regions'
import ReturnsApi from '@/api/returns'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccNumberInput from 'Components/controls/rcc-number-input.vue'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccDateInput from 'Components/controls/rcc-date-input'
import RccTextarea from 'Components/controls/rcc-textarea'
import RccSelect from 'Components/controls/rcc-select'
import RccAutocomplete from 'Components/controls/selects/autocomplete'

export default {
  components: {
    RccTextInput,
    RccTextarea,
    RccDateInput,
    RccSelect,
    RccFooterButtons,
    RccNumberInput,
    RccConfirmDialog,
    RccAutocomplete
  },

  mixins: [Page, Form, ReturnsApi],

  data() {
    return {
      suppliersOptions: [],
      networksOptions: [],
      regionsOptions: [],
      returnPointsOptions: [],
      storesOptions: [],
      returnStatusesOptions: [],
      detailsOptions: [],
      isShowConfirmDialog: false,
      returnPointsForCurrentNetwork: [],
      isLoadReturnPoints: false,
      isNew: false,

      form: {
        booking_date: null,
        booking_date_fact: null,
        application_code: null,
        supplier_id: null,
        pallets_num: null,
        pallets_num_fact: null,
        network_id: null,
        region_id: null,
        return_point_id: null,
        store_id: null,
        return_status_id: null,
        status_detail_id: null,
        number_1c: null,
        comment: null
      }
    }
  },

  computed: {
    selects() {
      return [
        {
          method: this.isNew ? getActiveSuppliersList : getAllSuppliersList,
          target: 'suppliersOptions',
          errorMessage: 'Не удалось загрузить данные для поставщиков'
        },
        { method: getNetworksList, target: 'networksOptions', errorMessage: 'Не удалось загрузить данные для сети' },
        { method: getRegionsList, target: 'regionsOptions', errorMessage: 'Не удалось загрузить данные для регионов' },
        {
          method: this.$apiMethods.stores.allList,
          target: 'storesOptions',
          errorMessage: 'Не удалось загрузить данные для складов'
        },
        {
          method: getReturnStatusesList,
          target: 'returnStatusesOptions',
          errorMessage: 'Не удалось загрузить данные для статусов возвратов'
        },
        {
          method: getDetailsList, target: 'detailsOptions', errorMessage: 'Не удалось загрузить данные для детализаций'
        }
      ]
    },

    additionalText() {
      return ''
    },

    returnPointsNoDataText() {
      return this.form.network_id ? 'Нет доступных точек возврата' : 'Сперва выберите сеть'
    },

    hasFactValidation() {
      return  this.form.return_status_id
        && this.returnStatusesOptions.find(({ id }) => id === this.form.return_status_id)?.is_validate
    }
  },

  methods: {
    async confirmReturnsChanges() {
      this.isSubmit = true

      this.confirmChanges(this.form.id)
        .then(() => {
          window.snackbar( 'Изменения подтверждены', 'info')
          this.form.attention = 2
        })
        .finally(() => { this.isSubmit = false })
    },

    async handleReturnStatusInput() {
      this.$refs.bookingDateFactRef.$refs.inputRef.$refs.inputRef.onFocus()
      this.$refs.bookingDateFactRef.$refs.inputRef.$refs.inputRef.blur()
      await this.$refs.bookingDateFactRef.$refs.inputRef.$refs.inputRef.validate()

      this.$refs.palletsNumFactRef.$refs.inputRef.$refs.inputRef.onFocus()
      this.$refs.palletsNumFactRef.$refs.inputRef.$refs.inputRef.blur()
      await this.$refs.palletsNumFactRef.$refs.inputRef.$refs.inputRef.validate()
    },

    // dcValidation(value) {
    //   if (this.isNew || this.form.network_id !== 1) {
    //     return true
    //   }

    //   return value || 'При выбранной сети X5 - поле РЦ не может быть пустым'
    // },

    handleNetworkInput() {
      if (this.form.network_id) {
        this.isLoadReturnPoints = true

        getReturnPointsList.bind(this)({ network_id: this.form.network_id })
          .then(({ items }) => {
            this.returnPointsForCurrentNetwork = items
          })
          .finally(() => this.isLoadReturnPoints = false)
      } else {
        this.returnPointsForCurrentNetwork = []
      }
    }
  },

  created() {
    getOptions.bind(this)(this.selects)
  }
}
</script>

<style lang="scss" scoped>
.returns-form{
  height: 100%;
  display: flex;
  flex-direction: column;

  &__layout {
    flex-grow: 1;
    display: flex;
    width: 100%;
    gap: 10%;
    padding: $base-form-padding;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__layout-section{
    width: 35%;

    @media screen and (max-width: 1450px) {
      width: 90%;
    }

    @media screen and (max-width: 850px) {
      width: 80%;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
</style>
